<template>
  <div style="text-align: left">商品列表</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="商品名称">
      <el-input
        v-model="seachForm.name"
        clearable
        size="small"
        placeholder="请输入商品名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="商品分类" style="margin-left: 20px">
      <el-select
        v-model="seachForm.categoryId"
        clearable
        size="small"
        placeholder="请选择分类"
      >
        <el-option
          v-for="item in classOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="initform"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="addgood">创建商品</el-button>
  </div>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="goodsPic" label="商品图片" width="180">
      <template #default="scope">
        <img
          :src="scope.row.imgUrl"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="name" label="商品名称"> </el-table-column>
    <el-table-column prop="categoryName" label="商品分类" width="180">
    </el-table-column>

    <el-table-column prop="basePrice" label="商品价格(元)" width="180">
    </el-table-column>
    <el-table-column prop="content" label="商品简述"> </el-table-column>
    <el-table-column prop="updateDate" label="时间" width="180">
    </el-table-column>
    <el-table-column prop="address" label="操作">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <el-button
            type="text"
            @click="editgood(scope.row)"
            style="margin-right: 10px"
            >编辑</el-button
          >
          <el-button
            type="text"
            style="margin-left: 0px !important"
            @click="deletegood(scope.row)"
            >删除</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      seachForm: {
        categoryId: '',
        name: ''
      },
      upData: [],
      baseUrl: '',
      tmpStr: '',
      classOption: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
    }
  },
  created() {
    this.baseUrl = this.$http.defaults.baseURL
    this.getClassOPtion().then(() => {
      this.getgoodsInfo(this.goodsSeachForm)
    })
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '商品管理',
        index: '/goodslist'
      },
      {
        name: '商品列表',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/productinfo/productinfos/1' + '?pageSize=' + val + '&' + (this.seachForm.name.trim() === '' ? '' : '&productName=' + this.seachForm.name) + (this.seachForm.categoryId.trim() === '' ? '' : '&categoryId=' + this.seachForm.categoryId))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setgoodsSeachForm',{...this.goodsSeachForm , currentpage : '1' , pageSize: val})
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.name.trim() === '' ? '' : '&productName=' + this.seachForm.name) + (this.seachForm.categoryId.trim() === '' ? '' : '&categoryId=' + this.seachForm.categoryId)
      const res = await this.$http.get('/nktdec/productinfo/productinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = val
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setgoodsSeachForm',{...this.goodsSeachForm , currentpage : val , pageSize: this.pageSize })
    },
    query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1'
      }
      return str 
    },
    async getgoodsInfo(obj) {
       var str = this.query('',obj)
      console.log(str)
      if(obj.categoryId){
        this.seachForm.categoryId = obj.categoryId
      }
      if(obj.productName){
        this.seachForm.productName = obj.productName
      }
      const res = await this.$http.get('/nktdec/productinfo/productinfos/' + str)
      this.tableData = res.data.content
       this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      // 循环获取图片
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setgoodsSeachForm',{...this.goodsSeachForm , currentpage : obj.currentpage , pageSize: '20'})
    },
    getClassinfo() {
      this.tableData.forEach(item => {
        var obj = this.classOption.find(classitem => classitem.id === item.categoryId)
        item.categoryName = obj.name
      })
    },
    async getClassOPtion() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=PRODUCT')
      this.classOption = res.data
    },
    getimgUrl() {
      this.tableData.forEach(async (item) => {
        // const res = await this.$http.get('/nktdec/uploader/images/' + item.mediaUrl1)
        item.imgUrl = this.imgBaseUrl + item.mediaUrl1
      })
      console.log(this.tableData)
    },
    editgood(row) {
      console.log(row)
      window.sessionStorage.setItem('GoodsInfo', JSON.stringify(row))
      this.$store.commit('setGoodsInfo', row)
      this.$router.push('/changegood/false')
    },
    async deletegood(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/productinfo/productinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getgoodsInfo(this.goodsSeachForm)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
        if (res.data.code === '0') {
          this.getgoodsInfo(this.goodsSeachForm)
          this.$message({
            type: 'error',
            message: '该商品以已上架！请确认'
          })
        }
      })
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.name.trim() === '' ? '' : 'productName=' + this.seachForm.name) + (this.seachForm.categoryId.trim() === '' ? '' : (this.seachForm.name.trim() === '' ? '' : '&') + 'categoryId=' + this.seachForm.categoryId)
      console.log(str)
      const res = await this.$http.get('/nktdec/productinfo/productinfos/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setgoodsSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    addgood() {
      this.$router.push('/changegood/true')
    },
    async initform() {
      console.log(this.seachForm)
      this.seachForm.categoryId = ''
      this.seachForm.name = ''
      const res = await this.$http.get('/nktdec/productinfo/productinfos/1')
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setgoodsSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    }
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    goodsSeachForm() {
      return this.$store.state.goodsSeachForm
    },
  }
}
</script>
<style scoped>
.lineform {
  display: flex;
  justify-content: flex-start;
}
.expendform {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.expendform .el-form-item {
  width: 48%;
}
</style>
