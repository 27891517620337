<template>
  <div style="margin-top: 15px; width: 700px; margin-left: 20px">
    <div style="text-align: left">商品分类</div>
    <div style="display: flex; justify-content: flex-start; margin-top: 30px">
      <!-- <el-input
        v-model="propName"
        placeholder="请输入产品名称！"
        style="width: 160px"
        size="mini"
      ></el-input> -->
      <el-button type="primary" size="mini" @click="addFirstTree"
        >添加</el-button
      >
    </div>
    <el-tree
      v-if="data.length > 0"
      :data="data"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      style="margin-top: 15px"
    >
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <div>
            <i class="el-icon-folder" @click="openpreview(data)"> </i>
            <!-- <img
              v-if="data.imgSrc"
              :src="data.imgSrc"
              style="width: 75px; height: 75px"
              alt=""
            /> -->
            <!-- <el-input
              v-if="InputShow && currentId === node.id"
              v-model="editName"
              placeholder="请输入修改得名称！"
              class="treeinput"
              style="margin-left: 5px"
            ></el-input> -->
            <!-- <span>{{ data }}</span> -->
            <span style="margin-left: 5px">{{ data.name }}</span>
            <el-tag v-if="data.place" type="success" style="margin-left:15px">{{data.place}}</el-tag>
            <el-tag v-if="data.indexed" type="success" style="margin-left:15px">首</el-tag>
            
          </div>

          <span>
            <el-button type="text" @click="edit(node, data)"> 修改 </el-button>
            <el-button
              type="text"
              style="color: #ff0000"
              @click="remove(node, data)"
            >
              删除
            </el-button>
            <!-- <el-button type="text" @click="append(data)"> 添加 </el-button> -->
          </span>
        </span>
      </template>
    </el-tree>
    <el-empty v-else description="暂无分类"></el-empty>
    <el-dialog
      title="分类信息"
      v-model="dialogVisible"
      width="360px"
      destroy-on-close
    >
      <el-form
        ref="optionForm"
        :model="classForm"
        :rules="rules"
        @resetFields="resetFieldsform"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="name" class="flexcol">
          <!-- <div class="flexrow"> -->
          <el-input
            v-model="classForm.name"
            clearable
            style="width: 200px"
            placeholder="请输入分类名称！"
          ></el-input>
          <!-- </div> -->
          <div
            style="
              color: rgba(0, 0, 0, 0.45);
              text-align: left;
              font-size: 13px;
              line-height: 20px;
              margin-top: 5px;
            "
          >
            <span>建议输入4字符</span>
          </div>
        </el-form-item>
        <el-form-item label="分类图片" >
          <el-upload
             :action="UploadUrl"
         :headers="{
           authorization: Token
         }"
            list-type="picture-card"
            class="goodsort"
            :file-list="fileList"
             :auto-upload="true"
        :before-upload="handlebeforupload"
            :on-success="changeFile"
            :limit="1"
          >
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
            <template #file="{ file }">
              <div style="width: 100%; height: 100%">
                <img :src="file.url" alt="" style="width: 100%; height: 100%" />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
           <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比1：1、图片大小60k为宜，单张图片不超过2M</span
      >
        </el-form-item>
        <el-form-item label="首页展示" class="flexrow flexcol">
          <el-switch v-model="classForm.indexed" active-color="#13ce66" inactive-color="#ff4949" />
        </el-form-item>
        <el-form-item label="排序系数" class="flexrow flexcol">
          <el-input-number v-model="classForm.place" :min="1" :max="10" :step="1" label="排序系数"></el-input-number>
        </el-form-item>
        <el-form-item label="备注" class="flexrow">
          <el-input
            v-model="classForm.note"
            clearable
            style="width: 200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini"
            >取 消</el-button
          >
          <el-button type="primary" size="mini" @click="submitClassInfo"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    return {
      data: [],
      propName: '',
      InputShow: false,
      editName: '',
      currentId: '',
      dialogVisible: false,
      dialogdata: {},
      classForm: {
        upperId: '',
        name: '',
        icon: '',
        type: 'PRODUCT',
        note: '',
        place: 1,
        indexed:true
      },
      rules: {
        name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
      },
      fileList: [],
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '商品管理',
        index: '/goodslist'
      },
      {
        name: '商品分类',
        index: '/sortmange'
      },]
    this.$store.commit('setBreadInfo', Arr)
    this.getClassInfo()
  },
  methods: {
    async getClassInfo() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos/?type=PRODUCT')
      console.log(res.data)
      this.data = res.data
      console.log(this.data)
    },
    // append(data) {
    //   const newChild = { id: 9, label: 'testtest', imgSrc: 'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1922340141,2474556219&fm=26&gp=0.jpg', children: [] }
    //   if (!data.children) {
    //     data.children = []
    //   }
    //   data.children.push(newChild)
    //   this.data = [...this.data]
    //   console.log(data)
    // },
    async remove(node, data) {
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/categoryinfo/categoryinfo/' + data.id)
        console.log(res)
        if (res.data.code === '1') {
          this.getClassInfo()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    edit(node, data) {
      console.log(data)
      this.dialogVisible = true
      this.fileList = []
      this.classForm = _.cloneDeep(data)
      this.fileList.push({
        url: this.imgBaseUrl + data.icon
      })
    },
    handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    handleRemove(file) {
      console.log(file)
      var i = this.fileList.findIndex(item => item.url === file.url)
      this.fileList.splice(i, 1)
      this.classForm.icon = ''
    },
    changeName(node) {
      console.log(node)
      this.currentId = node.id
      this.InputShow = true
    },
    addFirstTree() {
      this.dialogVisible = true
      if (this.classForm.id) {
        delete this.classForm.id
      }
      this.classForm.name = ''
      this.classForm.icon = ''
      this.classForm.note = ''
      this.fileList = []
    },
    async submitClassInfo() {
      if (this.classForm.icon.trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      console.log(this.classForm)
      const parmas = _.cloneDeep(this.classForm)
      const res = await this.$http.post('/nktdec/categoryinfo/categoryinfos', parmas)
      console.log(res.data)
      this.getClassInfo()
      // this.resetFieldsform()
      this.dialogVisible = false
    },
    openpreview(data) {
      console.log(data)
      this.dialogdata = data
      this.dialogVisible = true
    },
    async changeFile(res,file, fileList) {
      //this.imageUrl = URL.createObjectURL(file.raw);
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      // console.log(res)
      this.classForm.icon = res.result
    },
    resetFieldsform() {
      this.$refs.optionForm.resetFields()
    }
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  }
}
</script>
<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

</style>
